@import "../styles/common.scss";

.skills-grid {
  display: grid;
  padding: 0rem 2rem;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.2rem;
}

.skills-card {
  background-color: var(--color-tertiary);
  padding: 2rem;
  border-radius: 0.6rem;
  border: 1px solid var(--color-secondary);
}

.skills-category {
  margin: 0;
  margin-bottom: 1.4rem;
  color: var(--color-primary);
}

.skills-list {
  display: flex;
  flex-wrap: wrap;
  gap: 0.6rem;
}

@media screen and (max-width: 600px) {
  .skills-grid {
    grid-template-columns: 1fr;
  }
}