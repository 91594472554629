.nav-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 70px;
  z-index: 3;
  background-color: white;
}

.nav-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 100%;
}

.nav-top {
  background-color: transparent;
}

.nav-flex {
  display: flex;
  height: 100%;
  align-items: center;
  margin: 0rem 2rem;
  justify-content: space-between;
}

.logo {
  cursor: pointer;
}

.menu {
  text-decoration: none;
  list-style: none;
  display: flex;
  gap: 3.2rem;
  font-weight: 600;
  font-size: 1.2rem;

  li {
    cursor: pointer;
    position: relative;

    a {
      text-decoration: none;
      color: var(--text-color-primary);
    }
    .active,
    &:hover {
      font-weight: 700;
      &::before {
        content: "";
        position: absolute;
        top: -1.2rem; /* Adjust the distance of the border from the text */
        left: -5%;
        width: 100%; /* Adjust the width of the border */
        border: 2px solid var(--color-primary);
        border-radius: 4px;
      }
    }
  }
}

.menu-icon-wrapper {
  display: none;
  cursor: pointer;
  width: 50px;
  height: 50px;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */

  .svg-icon {
    width: 100%;
    height: 100%;

    &:hover path {
      fill: var(--color-primary);
    }
  }
}

@media screen and (max-width: 600px) {
  .menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: fixed;
    top: 43px;
    right: -300px;
    width: 300px;
    height: 100vh;
    padding: 60px 0px 0px 0px;
    background-color: var(--color-primary);
    box-shadow: 0 40px 60px rgba(0, 0, 0, 0.1);
    transition: 0.3s ease-in-out;

    &.menu-active {
      right: 0px;
    }

    li {
      margin-left: 2rem;

      a {
        color: white;
      }
      .active,
      &:hover {
        font-weight: 700;
        &::before {
          border-color: white;
          top: 120%; /* Adjust the distance of the border from the text */
          left: 0;
        }
      }
    }
  }

  .menu-icon-wrapper {
    display: block;
  }
}
