.container {
  max-width: 1440px;
  margin: 0 auto;
}

h1 {
  margin: 1.6rem 0rem;
}

section {
  padding: 2rem 0;
}

.section-header-container {
  display: grid;
  place-items: center;
}

.section-header {
  position: relative;
  margin: 3rem 0rem 5rem 0rem;
  text-align: center;
  font-size: 2rem;
  font-weight: 800;
  letter-spacing: 1.2px;

  &::before {
    content: '';
    position: absolute;
    top: -1.2rem; /* Adjust the distance of the border from the text */
    left: -5%;
    width: 110%; /* Adjust the width of the border */
    border: 2px solid var(--color-primary);
    border-radius: 4px;
  }
}