.timeline-grid {
  display: grid;
  grid-template-columns: 1fr 4px 1fr;
  column-gap: 2rem;
  padding: 0rem 1rem;
}

.timeline-bar {
  background-color: var(--color-primary);
  position: relative;
}

.timeline-circle {
  position: absolute;
  width: 1rem;
  height: 1rem;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--color-primary);
  border-radius: 50%;
}

.timeline-bottom {
  top: initial;
  bottom: 0;
}

.timeline-experience {
  background-color: var(--color-tertiary);
  padding: 1.8rem;
  border-radius: 0.6rem;
  max-width: 26rem;
  margin-bottom: 3rem;
  border: 1px solid var(--color-secondary);
}

.timeline-experience-title {
  margin: 0.5rem 0rem 0.8rem 0rem;
  font-size: 1.4rem;
  color: var(--color-primary);
}

.timeline-experience-company {
  font-size: 0.98rem;
  font-weight: 500;
}

.timeline-experience-description {
  line-height: 1.7rem;
}

.justify-right {
  justify-self: right;
}

.timeline-date {
  font-size: 1.2rem;
  font-weight: 500;
}

@media screen and (max-width: 450px) {
  .timeline-grid {
    grid-template-columns: 1fr 1px 1fr;
    column-gap: 0.6rem;
  }

  .timeline-circle {
    width: 0.5rem;
    height: 0.5rem;
  }

  .timeline-experience {
    padding: 1rem;
    margin-bottom: 1.2rem;
  }

  .timeline-experience-title {
    margin: 0.3rem 0rem;
    font-size: 1.1rem;
  }
  
  .timeline-experience-company {
    font-size: 0.8rem;
    font-weight: 500;
  }
  
  .timeline-experience-description {
    line-height: 1.2rem;
    font-size: 0.9rem;
  }

  .timeline-date {
    font-size: 1rem;
  }
}