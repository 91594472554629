.project-flex {
  display: flex;
  flex-wrap: wrap;
  gap: 1.2rem;
  margin: 0rem 2rem 3rem 2rem;
}

.project-card {
  width: calc((100% - 1.2rem - 4px) / 2);
  border-radius: 1rem;
  border: 1px solid var(--color-secondary);
  background-color: var(--color-tertiary);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.project-image {
  height: 500px;
}

.project-content {
  padding: 2rem;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  gap: 0.8rem;
  grid-template-areas:
    "title title title title title date date date"
    "title title title title title link link link"
    "address address address address address address address address"
    "advisor advisor advisor advisor advisor advisor advisor advisor"
    "description description description description description description description description";
}

.project-title {
  grid-area: title;
  font-size: 1.6rem;
  font-weight: 700;
  letter-spacing: 0.5px;
  color: var(--color-primary);
  word-break: break-word;
}

.project-date {
  grid-area: date;
  justify-self: end;
  font-size: 1.1rem;
}

.project-address {
  grid-area: address;
  font-weight: 500;
}

.project-links {
  grid-area: link;
  justify-self: end;
  display: flex;
  gap: 0.6rem;
}

.project-advisor {
  grid-area: advisor;
  font-style: italic;
}

.project-description {
  grid-area: description;
  line-height: 1.6rem;
  margin-bottom: 16px;
}

.project-skills {
  border-top: 1px solid var(--color-secondary);
  padding: 1.4rem 2rem;
  display: flex;
  flex-wrap: wrap;
  gap: 0.7rem;
  margin-top: auto;
}

@media screen and (max-width: 650px) {
  .project-card {
    width: 100%;
  }
}
