@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'Inter', 'Segoe UI', 'Roboto', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f6fbff;
  color: #3d3d3d;
  font-weight: 300;
  --color-primary: #00ac93;
  --color-secondary: #e6f4f1;
  --color-tertiary: #FCFFFE;
  --color-text-primary: #3d3d3d;
  --color-hover: #04C383;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-moz-selection { /* Code for Firefox */
  color: white;
  background: var(--color-primary);
}

::selection {
  color: white;
  background: var(--color-primary);
}
